import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Footer from '../../components/footer';
import Wrapper from '../../components/wrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const IndexPage = () => {
  return (
    <Layout navtype="dark" title="Upscribed">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" offset={0}>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../images/upscribed/head.jpg"
          alt="Upscribed"
          className="hero-image"
        />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className="first">
        <svg width="30" className="project-logo" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.32 16.874C12.32 18 12.422 18.819 12.616 19.28C12.81 19.75 13.136 20.13 13.596 20.416C14.046 20.703 14.596 20.846 15.251 20.846C15.925 20.846 16.517 20.683 17.007 20.376C17.497 20.058 17.842 19.659 18.027 19.166C18.22 18.686 18.312 17.754 18.302 16.382L18.312 5.345H20.642L15.32 0L10 5.345H12.327V16.875H12.317L12.32 16.874Z" fill="#0B354E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.0443 8.79491L22.2262 6.96191V15.8699C22.2262 17.9279 22.1033 19.3619 21.8693 20.1599C21.6443 20.9499 21.2053 21.7269 20.5513 22.4739C19.9384 23.1887 19.1697 23.7532 18.3043 24.1239C17.4573 24.4809 16.4663 24.6439 15.3343 24.6439C13.8623 24.6539 12.5643 24.3169 11.4723 23.6309C10.3823 22.9579 9.60625 22.0979 9.13625 21.0839C8.66625 20.0699 8.43125 18.3299 8.43125 15.8939V6.97991L6.76525 8.64991L6.77525 8.65991C6.72525 8.69991 6.67325 8.75191 6.61125 8.80291C1.79125 13.6259 1.80125 21.4589 6.61125 26.2809C11.4213 31.1249 19.2352 31.1249 24.0553 26.2809C28.8653 21.4389 28.8653 13.6169 24.0452 8.79391L24.0443 8.79491Z" fill="#F36C21"/>
        </svg>
      </Wrapper>
      <Wrapper>
        <p>
          2015-2016. <a href="https://www.upscribed.com" target="_blank" rel="noreferrer">Upscribed</a> is a web-based platform to manage the content marketing lifecycle
        </p>

        <strong className="sub-title">Challenges and goals</strong>
        <h3>Build the MVP in 2 months</h3>
        <p>
            ​​​​​​The MVP development took 60 days. To hit the deadlines, we used a rails based skeleton with the Zurb Foundation CSS framework. As part of the MVP we developed the work of the editor, administrator, and data import process.​​​​​​
        </p>
        </Wrapper>
        <div className="center">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/upscribed/mvp.png"
              alt="MVP Scheme"
              />
          </AnimationOnScroll>
          <br />
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/upscribed/editor.png"
              alt="Editor Scheme"
            />
          </AnimationOnScroll>
          <br />
          <p>The Interaction of the processes of the editor scheme</p>
        </div>
        <Wrapper>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/upscribed/orders.png"
              alt="Orders"
              className="prototype"
              />
          </AnimationOnScroll>
          <br />
          <br />
          <h3>Next Steps</h3>
          <p>​​​​​​​After the MVP launch, we decided to spend some time on prettifying the UI. I've shared with the front-end team the list of redeclared CSS variables from the Zurrb Foundation and additional guides for new components. As a result, we fully updated the UI of the app.</p>
          <strong>Bulk Import</strong>
          <p>I've designed a comprehensive, step-by-step process for importing customers and orders to simplify the start of work for new clients. </p>
        </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
        <div className="center">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/upscribed/import.png"
            alt="Editor Scheme"
          />
        </div>
      </AnimationOnScroll>
      <Wrapper>
        <p>Also, I've designed an online content commenting tool for different roles (client, editor, collaborator). The approved content gets changed to the "Approved" status.</p>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/upscribed/collaboration.png"
            alt="Collaboration"
            className="prototype"
          />
        </AnimationOnScroll>
        <br/>
        <br/>
        <p>The approved content can be published to various services (Facebook, Twitter, Pinterest, WordPress, etc.).</p>
        <br/>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/upscribed/scheduler.png"
            alt="Scheduler"
            className="prototype"
          />
        </AnimationOnScroll>
        <br/><br/>
        <strong>Styleguide</strong>
        <p>I've prepared the style guide to maintain integrity and speed up all further development.</p>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/upscribed/guide.jpg"
            alt="Styleguide"
          />
        </AnimationOnScroll>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true" duration={.5}>
        <div className="wide-scheme">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/upscribed/screens.png"
            alt="Some screenshots"
          />
        </div>
      </AnimationOnScroll>
      </AnimationOnScroll>
    <Footer />
  </Layout>
  )
}

export default IndexPage
